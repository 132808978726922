import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom' // Import useHistory from react-router-dom

const ExternalLink = ({ url }) => {
  const hiddenButtonRef = useRef(null)
  const history = useHistory() // useHistory for programmatic navigation

  useEffect(() => {
    // Programmatically click the hidden button to open the link in a new tab
    if (hiddenButtonRef.current) {
      hiddenButtonRef.current.click()
      // After opening the external link, navigate to the home page without refresh
      history.push('/') // Navigate to the home page
    }
  }, [history])

  return (
    <div>
      {/* Hidden button to trigger programmatically */}
      <button
        ref={hiddenButtonRef}
        type="button" // Explicitly set the button type to "button"
        style={{ display: 'none' }} // Hide the button
        onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
      >
        Open External Link
      </button>
    </div>
  )
}

export default ExternalLink
