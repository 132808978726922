import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact Us'),
        href: 'mailto:info@catapultprotocol.io',
      },
      {
        label: t('Community'),
        href: 'https://t.me/CatapultToken',
      },
      {
        label: t('CPT Token'),
        href: 'https://egonscan.com/token/0x80E356A9308A3A261992DEAc6599582aa80aE6E3/',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Support'),
        href: 'mailto:info@catapultprotocol.io',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: t('Github'),
        href: 'https://github.com/CatapultProtocol',
      },
    ],
  },
]
