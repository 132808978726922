import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL, REFERRAL_KEY } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'


export const stakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  const referrer = localStorage.getItem(REFERRAL_KEY) ? localStorage.getItem(REFERRAL_KEY) : ZERO_ADDRESS
  const tx = await masterChefContract.deposit(pid, value, referrer, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  const tx = await masterChefContract.withdraw(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestFarm = async (masterChefContract, pid) => {
  const gasPrice = getGasPrice()
  const referrer = localStorage.getItem(REFERRAL_KEY) ? localStorage.getItem(REFERRAL_KEY) : ZERO_ADDRESS
  const tx = await masterChefContract.deposit(pid, '0', referrer, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}
