import { SerializedFarmConfig } from 'config/constants/types'
import fetchFarm from './fetchFarm'

const fetchFarms = async (farmsToFetch: SerializedFarmConfig[]) => {
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      console.log("farmsFetchingInternal")
      console.log(farmConfig)
      try {

        const farmTest = await fetchFarm(farmConfig)
        console.log("internalCallGoingOkeay")
        console.log(farmTest);
        console.log(`test ${farmTest}`)
      }catch(e){

          console.log("internalFarmsFailed");
          console.log(e)
      }
      const farm = await fetchFarm(farmConfig)
      console.log(farm)
      const serializedFarm = { ...farm, token: farm.token, quoteToken: farm.quoteToken }

      return serializedFarm
    }),
  )
  return data
}

console.log("fetchingFarms==>")

console.log(fetchFarms)
export default fetchFarms
