import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cpt,
    earningToken: serializedTokens.cpt,
    contractAddress: {
      271271: '',
      271: '0x0Cc6b97cC115d898D3f3703D58EAe00B5753285e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 0,
    isFinished: true,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.cpt,
    earningToken: serializedTokens.cpt,
    contractAddress: {
      271271: '',
      271: '0x60B152Ae62dC394B150c43E59e16CEbe2d7bb20f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.wbnb,
    earningToken: serializedTokens.cpt,
    contractAddress: {
      271271: '',
      271: '0xc35af646bb2648ba83cf02323da3ad2f51105a54',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
  },
]

export default pools
